<messages>["../Email"]</messages>

<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="emailData"
    :page.sync="pagination.page"
    :items-per-page.sync="pagination.rowsPerPage"
    :sort-by.sync="pagination.sortBy"
    :sort-desc.sync="pagination.descending"
    :footer-props="{...footerProps, 'items-per-page-options': rowsPerPageItems}"
    :server-items-length="totalCount"
    :loading="loading">
    <template #item="props">
      <tr>
        <td>
          <router-link
            :to="detailsLink (props.item)"
            :class="{deletedItemLink: props.item.deleted}"
            v-text="props.item.emailAddress"/>
        </td>
        <td v-if="!compactTable">
          {{ props.item.destEMailAddress }}
        </td>
        <td v-if="!compactTable">
          {{ props.item.handle }}
        </td>
        <td v-if="!hideClient && !veryCompactTable">
          <client-link :id="props.item.clientId"/>
        </td>
        <td>
          <action-buttons
            :value="isActionButtonsActive (props.item.versionId)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.versionId) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
    <template #no-data>
      <search-table-no-data
        :is-loading="loading"
        :text="$t ('list.empty')"/>
    </template>
  </v-data-table>
</template>

<script>
  import ActionButtons from '@/app/core/components/ActionButtons'
  import ClientLink from '@/app/core/components/ClientLink'
  import SearchTableNoData from '@/app/core/components/Search/SearchTableNoData'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'

  export default {
    name: 'EmailTable',

    components: {
      ActionButtons,
      ClientLink,
      SearchTableNoData
    },

    mixins: [paginationMixins, actionButtonsHelper],

    props: {
      emailData: {type: Array, default: () => []},
      totalCount: {type: Number, default: 0},
      rowsPerPageItems: {
        type: Array,
        default: undefined
      },
      loading: Boolean,
      hideClient: Boolean
    },

    computed: {
      compactTable () {
        return this.$vuetify.breakpoint.smAndDown
      },

      veryCompactTable () {
        return this.$vuetify.breakpoint.xs
      },

      headers () {
        return [
          {
            text: this.$t ('list.header.address'),
            value: 'emailAddress'
          },
          ...this.compactTable
            ? []
            : [{
              text: this.$t ('list.header.destAddress'),
              value: 'destEMailAddress'
            }],
          ...this.compactTable
            ? []
            : [{
              text: this.$t ('list.header.handle'),
              value: 'handle'
            }],
          ...(this.hideClient || this.veryCompactTable)
            ? []
            : [{
              text: this.$t ('list.header.client'),
              value: 'clientId'
            }],
          {
            text: this.$t ('list.header.actions'),
            sortable: false
          }
        ]
      }
    },

    methods: {
      /**
       * handle pagination state changes
       *
       * @param newValue      new pagination state
       * @param oldValue      old pagination state
       */
      onPaginationStateChanged (newValue, oldValue) {
        this.$emit ('paginationStateChanged', {newValue, oldValue})
      },

      /**
       * Generate the link data for the email details view.
       *
       * @param {Object} email     the email to create the link for
       */
      detailsLink (email) {
        return {name: 'service.emailview', params: {id: email.versionId}}
      },

      getActionButtons (item) {
        return [
          {
            action: 'view',
            versionId: item.versionId,
            icon: 'visibility',
            tooltip: this.$t ('general.button.view')
          }
        ]
      },

      processActionButton (button) {
        switch (button.action) {
          case 'view':
            this.$router.push (
              {
                name: 'service.emailview',
                params: {id: button.versionId}
              })
            break
        }
      }
    }
  }
</script>
