<messages>["./Email"]</messages>

<template>
  <base-layout :mw="$vuetify.breakpoint.lgAndDown ? '2' : '3'">
    <v-col cols="12" lg="8" xl="4">
      <!-- search filter -->
      <email-filter
        :value="filter || undefined"
        :visible="showFilter"
        :search-disabled="isLoading"
        @input="onFilterChange"
        @visibilityChanged="onFilterVisibilityChange"/>
    </v-col>

    <!--search table-->
    <v-col :class="tableClasses">
      <v-card v-if="isResultTableVisible">
        <v-card-title primary-title>
          <div
            v-t="'list.title'"
            class="text-h5"/>
        </v-card-title>
        <v-card-text>
          <email-table
            v-bind="paginationState"
            :email-data="resultList"
            :total-count="totalCount"
            :hide-client="mayViewOnlyOwnObjects"
            :rows-per-page-items="rowsPerPageItems"
            :loading="isLoading"
            @paginationStateChanged="({newValue, oldValue}) =>
              onPaginationStateChanged (newValue, oldValue)"/>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex'

  import SearchPage from '@/app/core/mixins/SearchPage'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import EmailTable from './Search/EmailTable'
  import EmailFilter from './Search/EmailFilter'

  export default {
    components: {
      BaseLayout,
      EmailTable,
      EmailFilter
    },

    mixins: [SearchPage],

    data () {
      return {
        OPERATION: 'emailstatus/listEmails'
      }
    },

    computed: {
      ...mapState ('filter', [
        'emailSearchQuery'
      ]),
      ...mapGetters ({
        mayViewOnlyOwnObjects: 'auth/mayViewOnlyOwnObjects'
      }),

      tableClasses () {
        return this.mayViewOnlyOwnObjects
          ? ['col-8', 'col-xl-6']
          : ['col-12', 'col-xl-8']
      }
    },

    methods: {
      // --- mutations and actions, mapped from vuex ---------------------------
      ...mapMutations ({
        storeSearchQuery: 'filter/setEmailSearchQuery'
      }),

      /**
       * implementation of getting the stored search filter, which will be used
       * on component creation
       */
      getStoredSearchQuery () {
        return this.emailSearchQuery
      }
    }
  }
</script>
