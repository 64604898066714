<messages>["../Email"]</messages>
<template>
  <search-filter-wrapper
    v-model="filterVisibilityModel"
    type="email"
    :quick-filter-items="quickFilterItems"
    :additional-quick-filter-items="additionalQuickFilterItems"
    :search-disabled="searchDisabled"
    @deleteFilter="onDeleteFilter"
    @reset="resetSearchFilter"
    @search="doSearch"
    @focus="focus">
    <template #basic>
      <!-- domain name filters -->
      <v-row>
        <v-col
          class="pa-1"
          cols="6">
          <v-text-field
            ref="emailAddress"
            v-model.trim="filterParams.emailAddress"
            :label="$t ('filter.emailAddress')"/>
        </v-col>
        <v-col
          class="pa-1"
          cols="6">
          <v-text-field
            ref="handle"
            v-model.trim="filterParams.handle"
            :label="$t ('filter.handle')"/>
        </v-col>
        <v-col
          v-if="mayViewAllObjects || (mayViewSubEntitiesObjects && hasSubClients)"
          class="pa-1"
          cols="12">
          <client-select
            ref="clientId"
            v-model="filterParams.clientId"
            for-view
            show-inactive
            nullable/>
        </v-col>
      </v-row>
    </template>
  </search-filter-wrapper>
</template>

<script>
  import {mapGetters} from 'vuex'

  import SearchFilterWrapper
    from '@/app/core/components/Search/SearchFilterWrapper'

  import ClientSelect from '@/app/core/components/ClientSelect'

  import SearchFilterMixinCreator from '@/app/core/mixins/SearchFilterCreator'
  import QuickFilterMixin from '@/app/core/mixins/QuickFilterMixin'
  import InputHelper from '@/app/core/mixins/InputHelper'

  /**
   * default email search filter parameters
   * @type {Object}
   */
  export const defaultValue = () => ({
    emailAddress: '',
    handle: '',
    clientId: null
  })

  export default {
    name: 'EmailFilter',

    components: {
      SearchFilterWrapper,
      ClientSelect
    },

    mixins: [
      SearchFilterMixinCreator (defaultValue),
      InputHelper,
      QuickFilterMixin
    ],

    computed: {
      ...mapGetters ({
        hasClient: 'auth/hasClient',
        mayViewAllObjects: 'auth/mayViewAllObjects',
        mayViewSubEntitiesObjects: 'auth/mayViewSubEntitiesObjects',
        hasSubClients: 'auth/hasSubClients'
      })
    }
  }
</script>
